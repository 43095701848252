.top-menu {
    background: #3E5570;
    color: white;
    padding-top: 0.25em !important;
    padding-bottom: 0.25em !important;
}

.settings-button:hover {
    cursor: pointer;
}

.top-menu-navbar {
    position: fixed;
    left: 0;
    right: 300px;
    z-index: 512;
}
